<!--
 * @Descripttion: 
 * @version: 
 * @Author: hutian
 * @Date: 2021-06-29 16:57:31
 * @LastEditors: hutian
 * @LastEditTime: 2021-06-30 13:56:36
-->
<template>
  <div class="set_dealt">
    <a-modal v-model="visible" title="查看商品" width="70%" @ok="onSubmit" okText="确定" :maskClosable="false">
      <a-form-model :label-col="{ xs: 3 }" :wrapper-col="{ xs: 21 }">
        <a-form-model-item label="合同号">
          <a-input v-model="rowData.contractNo"></a-input>
        </a-form-model-item>
        <a-form-model-item label="选择商品" v-if="tableData.length <= 0">
          <a-button type="primary" @click="onCheckGoods">选择商品</a-button>
        </a-form-model-item>
        <div class="goods" v-if="tableData.length > 0">
          <a-table
            :data-source="tableData"
            :loading="tableLoading"
            :rowKey="(record) => record.id"
            :pagination="false"
            bordered
          >
            <a-table-column title="商品名称" data-index="goodsName"></a-table-column>
            <a-table-column title="标签图标" data-index="goodsPicture" align="center">
              <template slot-scope="text">
                <ZoomMedia style="height: 20px" :value="text"></ZoomMedia>
              </template>
            </a-table-column>
            <a-table-column title="规格" data-index="goodsStr" align="center"></a-table-column>
            <a-table-column title="品牌" data-index="brandName" align="center"></a-table-column>
            <a-table-column title="建议零售价" data-index="price" align="center">
              <template slot-scope="text">{{ text.toFixed(2) }}</template>
            </a-table-column>
            <a-table-column title="数量" data-index="number" align="" :width="120">
              <!-- <template slot-scope="text, row">
                <a-input-number v-model="row.number" :min="1"></a-input-number>
              </template> -->
            </a-table-column>
            <a-table-column title="实际零售价" data-index="" align="" :width="120">
              <template slot-scope="text, row">
                <a-input-number v-model="row.finalPrice" :min="1"></a-input-number>
              </template>
            </a-table-column>
          </a-table>
        </div>
      </a-form-model>
      <ProductModal ref="ProductModal" @get-product-list="getProductList"></ProductModal>
    </a-modal>
  </div>
</template>

<script>
import ProductModal from '@/views/dealer_customer_info/components/ProductModal.vue'

export default {
  name: 'set_dealt',
  components: {
    ProductModal,
  },
  data() {
    return {
      visible: false,
      rowData: {},
      tableData: [],
      tableLoading: false,
      dataList: [],
    }
  },

  computed: {},

  created() {},

  methods: {
    show(row) {
      this.dataList = []
      this.rowData.id = row.id
      this.rowData.cusId = row.cusId
      this.axios.get(`/api/dealer/customer/dealerCustomerOrder/selectById/${row.id}`).then((res) => {
        this.tableData = res.body.dealerCustomerOrderDetails.map((e) => {
          return {
            ...e,
            finalPrice: e.price,
          }
        })
      })
      this.visible = true
    },

    // 获取选中商品
    getProductList(data) {
      const arr = new Map()
      let list = this.tableData.concat(data)
      let tmpArr = list.map(e => {
        return {
          ...e,
          finalPrice: e.price,
        }
      })
      this.tableData = tmpArr.filter((e) => !arr.has(e.id) && arr.set(e.id, 1))
    },

    onCheckGoods() {
      this.$refs.ProductModal.isShow()
    },

    // 提交数据
    onSubmit() {
      this.rowData.dealerCustomerOrderDetails = this.tableData
      this.axios.post(`/api/dealer/customer/dealerCustomerOrder/setStatus`, this.rowData).then((res) => {
        this.$notification.success({ message: res.message })
        this.$emit('reload')
        this.visible = false
        this.rowData = {}
      })
    },
  },
}
</script>

<style lang='scss' scoped>
</style>